<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Add Fund</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="clientfundlist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bd1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:right>
                            <Button label="Add Fund" icon="pi pi-plus" class=" p-mr-2" @click="addFundDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Receipt Date" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize"> {{ format_timestamp(data.br5) }}</div>
                        </template>
                    </Column>
                    <Column header="Payment Date" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize"> {{ format_date(data.br6) }}</div>
                        </template>
                    </Column>
                    <Column header="Payment Mode" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.br7 == 1"> Payment Gateway </div>
                                <div v-else-if="data.br7 == 2"> Cheque </div>
                                <div v-else-if="data.br7 == 3"> NEFT / RTGS</div>
                                <div v-else-if="data.br7 == 4"> IMPS</div>
                                <div v-else-if="data.br7 == 5"> NEFT / RTGS </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Amount" headerStyle="width: 19%" :class="{ 'text-right': true }"> //p-d-flex p-jc-end
                        <template #body="{ data }">
                            <div >{{ formatCurrency(data.br20) }}</div>
                        </template>
                    </Column>
                    <Column header="Receipt" headerStyle="width: 20% " headerClass="p-text-center">
                        <template #body="{ data }">
                            <div class="p-text-center" :style="{ 'padding-right': '70%' }">
                                <a v-if="data.br16 != '' && data.br16 != null" class="btn p-button-rounded indigo-color"
                                    target="_blank" :href="data.br16"><i class="pi pi-download"></i></a>
                                <span v-if="data.br16 == '' || data.br16 == null">-</span>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add client dialog start here -->
    <Dialog v-model:visible="addClientFundStatus" :style="{ width: '800px' }" header="Add Fund" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="billingcomapny">
                        Billing Company
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="billingcomapny" v-model="billingcomapny" :options="billingcomapnyList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.billingcomapny.$error">{{
                        v$.billingcomapny.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="paymentmode">
                        Payment Mode
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="paymentmode" v-model="paymentmode" :options="paymentmodeList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.paymentmode.$error">{{
                        v$.paymentmode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="fundamount">
                        Fund Amount
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="fundamount" class="p-text-capitalize" v-model.trim="fundamount" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !fundamount }" />
                    <small class="p-invalid p-error" v-if="v$.fundamount.$error">{{
                        v$.fundamount.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="paymentdate">
                        Payment Date
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div v-if="this.paymentmode.value == 2">
                        <Calendar :monthNavigator="false" :yearNavigator="false" :showIcon="true" v-model="paymentdate"
                            placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" appendTo="body" required="true"
                            :manualInput="false" autofocus :class="{ 'p-invalid': submitted && !paymentdate }" />
                    </div>
                    <div v-else-if="this.paymentmode.value != 2">
                        <Calendar :monthNavigator="false" :yearNavigator="false" :showIcon="true" v-model="paymentdate"
                            :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" appendTo="body"
                            required="true" :manualInput="false" autofocus
                            :class="{ 'p-invalid': submitted && !paymentdate }" />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.paymentdate.$error">{{
                        v$.paymentdate.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="orderid">
                        Order ID / UTR No.
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="orderid" class="p-text-capitalize" v-model.trim="orderid" required="true" maxlength="30"
                        autofocus :class="{ 'p-invalid': submitted && !orderid }" />
                    <small class="p-invalid p-error" v-if="v$.orderid.$error">{{
                        v$.orderid.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="remark">
                        Remark
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="remark" v-model="remark" :autoResize="true" rows="2" cols="30" maxlength="250"
                        required="true" autofocus :class="{ 'p-invalid': submitted && !remark }" />
                    <small class="p-invalid p-error" v-if="v$.remark.$error">{{
                        v$.remark.$errors[0].$message
                    }}</small>
                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addClientFundBtn" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add client dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
import moment from 'moment';
import { useRoute } from 'vue-router';
import router from '@/router';

export default {
    data() {
        return {
            v$: useValidate(),
            clientfundlist: [],
            page_no: 0,
            totalRecords: 0,
            partyname: '',
            partyList: '',
            loading: false,
            addClientFundStatus: false,
            billingcomapny: '',
            billingcomapnyList: [],
            paymentmode: '',
            paymentmodeList: [
                { label: 'Cheque', value: 2 },
                { label: 'NEFT / RTGS', value: 3 },
                { label: 'IMPS', value: 4 },
            ],
            fundamount: '',
            paymentdate: '',
            orderid: '',
            remark: '',
            showLoader: false,
            routeParam: '',
            todaysDate: moment()._d,
            localClientName: '',

        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;

        this.localClientName = localStorage.localClientName;
        this.loading = true;
        // this.paymentmode.value = 0;
        this.getClientFund({ clientId: this.routeParam });
        // this.getclientdetails({ maa1: this.routeParam });
    },

    validations() {
        return {
            billingcomapny: { required: helpers.withMessage('Please select billing company', required) },
            paymentmode: { required: helpers.withMessage('Please select payment mode', required) },
            fundamount: { required: helpers.withMessage('Please enter amount', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
            paymentdate: { required: helpers.withMessage('Please select date', required) },
            orderid: { required: helpers.withMessage('Please enter order id / UTR No', required) },
            remark: { required: helpers.withMessage('Please enter remark', required) },
        };
    },
    methods: {
        getClientFund(ev) {
            this.loading = true;
            this.ApiService.getClientFund(ev).then((data) => {
                if (data.status == 200) {
                    this.clientfundlist = data.data.result;
                    this.totalRecords = data.data.count;
                } else {
                    this.clientfundlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        getBrands(ev) {
            this.loading = true;
            this.ApiService.getbrandsforchain(ev).then((data) => {
                if (data.status == 200) {
                    this.partyList = data.data;
                } else {
                    this.partyList = '';
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getClientFund({ page_no: event.page, clientId: this.routeParam });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addFundDialogOpen() {
            this.addClientFundStatus = true;
            this.ApiService.getBillingCompany(this.routeParam).then((data) => {
                if (data.status == 200) {
                    this.billingcomapnyList = data.data;
                } else {
                    this.billingcomapnyList = null;
                }
            });

        },
        addClientFundBtn() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            // this.showLoader = true;
            fields['company_id'] = this.billingcomapny.value;
            fields['company_name'] = this.billingcomapny.label;
            fields['stage_id'] = this.paymentmode.value;
            fields['stage_name'] = this.paymentmode.label;
            fields['fundamount'] = this.fundamount;
            fields['order_id'] = this.orderid;
            fields['remark'] = this.remark;
            fields['next_followup_datetime'] = moment(this.paymentdate).format('YYYY-MM-DD');
            fields['client_id'] = this.routeParam;
            // this.getclientdetails({ maa1: this.routeParam });
            this.v$.$validate();
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addClientFund(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.addClientFundStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.billingcomapny = 0;
                        this.paymentmode = "";
                        this.fundamount = 0;
                        this.orderid = "";
                        this.remark = "";
                        this.paymentdate = "";
                        this.showLoader = false;
                        this.submitted = false;
                        this.getClientFund({ clientId: this.routeParam });
                    } else {
                        this.submitted = false;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "clientplan",
                params: { clientSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "client-role",
                params: { clientSubId: this.routeParam },

            });
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
    },
};
</script>
<style scoped lang="scss">
.text-right {
    text-align: right;
}
</style>
